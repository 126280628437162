<template>
  <milestone-wizard v-if="creationMode" class="milestones" @close="onWizardClose" @done="onMilestoneAdded" />
  <div v-else class="milestones">
    <Loading v-if="isGetCollectionRequestPending" />
    <template v-else>
      <div class="top-line">
        <div>
          <p-button variant="text" color="secondary" @click="goBack">BACK</p-button>
          <span class="label">/</span>
          <span class="label">{{ workflowTitle }}</span>
        </div>
        <div>
          <p-button v-if="$hasPermission('workflows')" color="primary" @click="addMilestone">+ New</p-button>
          <p-button v-if="$hasPermission('workflows')" color="secondary" @click="onImport({})">Import</p-button>
        </div>
      </div>
      <div v-if="collection" class="milestones-wrapper">
        <template v-if="collection.length">
          <milestone
            v-for="(milestone, index) in collection"
            :key="milestone.id"
            :order="index + 1"
            :milestone="milestone"
          />
        </template>
        <div v-else class="empty-milestones">No milestones created</div>
      </div>
      <export-modal v-if="importModal" :milestone="importModal" @cancel="importModal = null" @import="onImported" />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Button from '../common/Button.vue';
import MilestoneWizard from './MilestoneWizard.vue';
import ExportModal from './ImportModal.vue';
import Loading from '@/components/common/Loading';

import Milestone from './Milestone.vue';
export default {
  components: {
    Milestone,
    'p-button': Button,
    MilestoneWizard,
    ExportModal,
    Loading
  },
  data() {
    return {
      creationMode: false,
      editedMilestone: null,
      importModal: null
    };
  },
  computed: {
    ...mapState({
      collection: s => s.milestones.collection,
      isGetCollectionRequestPending: s => s.milestones.isGetCollectionRequestPending,
      workflowTitle() {
        return this.$store.state.workflows.collection?.find(w => w.id === this.$route.params.id)?.title;
      }
    })
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('milestones/getCollection', this.$route.params.id),
      this.$store.dispatch('milestones/getContracts'),
      this.$store.dispatch('workflows/getCollection')
    ]);
  },
  methods: {
    addMilestone() {
      this.creationMode = true;
    },
    onMilestoneAdded() {
      this.creationMode = false;
      this.$store.dispatch('milestones/getCollection', this.$route.params.id);
    },
    onWizardClose() {
      this.creationMode = false;
    },
    onImport(milestone) {
      this.importModal = milestone;
    },
    async onImported(str) {
      try {
        const data = JSON.parse(str);
        const payload = {
          workflowId: this.$route.params.id,
          data
        };
        try {
          await this.$store.dispatch(`milestones/create`, payload);
        } catch (e) {
          this.$toast.error({
            title: 'Import failed',
            message: 'Contact our developers or try again later.'
          });
        }
        this.$toast.success({
          title: 'Import successful',
          message: `Milestone was created`
        });
        this.$store.dispatch('milestones/getCollection', this.$route.params.id);
      } catch (e) {
        this.$toast.error({
          title: 'Import failed',
          message: `Reason: import string is invalid`
        });
      } finally {
        this.importModal = null;
      }
    },
    async goBack() {
      this.$router.push(`/workflows`);
    }
  }
};
</script>
<style lang="scss" scoped>
.milestones {
  width: 100%;
  padding: 0 20px;
  .top-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    :not(:last-child) {
      margin-right: 10px;
    }
  }
  .empty-milestones {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .milestones-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
  }
  .label {
    font-weight: 500;
    color: var(--theme-primary);
  }
}
</style>
