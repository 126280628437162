<template>
  <!-- 'id', 'type' transitions, notifications-->
  <div :class="['tile', type, source, node.data.props.isEventSource ? 'event-source' : '', selected(), node.data.props.status]">
    <div class="title">
      <span class="for-dock">
        <div class="task-title">{{ node.data.props.title }}</div>
        <div class="task-description">{{ node.data.props.description }}</div>
      </span>
      <span class="for-board">
        <div v-if="node.data.props.durationHours" class="duration" :class="{ changesApplied: changes && changes.durationHours }">
          <span v-if="!isEditingDuration" @click="editDuration">
            <md-icon size="sm" name="alarm" />
            <span v-if="!isInCompareMode || !changes.durationHours"> {{ node.data.props.durationHours }}h </span>
            <span v-else class="show-text-changed">
              <span class="previous"> {{ changes.durationHours.curr }}h </span>
              <span> {{ changes.durationHours.next }}h </span>
            </span>
          </span>
          <p-text-field
            v-else
            v-model="localDuration"
            placeholder="Duration"
            type="number"
            @blur="onDurationChange"
            @click.native.stop="() => false"
            @dblclick.native.stop="() => false"
            @pointerdown.native.stop="() => false"
          />
        </div>
        <div v-if="node.data.props.isLoadingCount" class="loading-wrapper">
          <md-icon size="sm" name="loading" />
        </div>
        <div v-if="!isInCompareMode || !changes.title" class="title-text">
          <div class="text-holder">
            <span v-if="!isEditingTitle">{{ node.data.props.title }}</span>
            <p-text-field
              v-else
              v-model="localTitle"
              placeholder="Task title"
              @click.native.stop="() => false"
              @dblclick.native.stop="() => false"
              @pointerdown.native.stop="() => false"
            />
          </div>
          <div class="buttons-holder">
            <template v-if="!isEditingTitle">
              <p-button
                v-if="!node.data.props.isEventSource && selected() && node.data.props.type !== 'finish'"
                title="Rename step"
                @click="isEditingTitle = true"
              >
                &#9998;
              </p-button>
            </template>
            <template v-else>
              <p-button variant="text" @click="onSave"> <md-icon size="sm" name="check" /></p-button>
              <p-button variant="text" @click="onCancel"><md-icon size="sm" name="close" /></p-button>
            </template>
          </div>
        </div>
        <div v-else class="show-text-changed">
          <span class="previous">{{ changes.title.curr }}</span>
          <span>{{ ' ' }}</span>
          <span class="next">{{ changes.title.next }}</span>
        </div>
        <p-button v-if="!node.data.props.notDeletable && selected() && canDelete" class="remove-button" variant="text" @click="onNodeRemove(node)"
          ><md-icon name="close"
        /></p-button>
      </span>
    </div>
    <div class="statuses-wrapper">
      <div class="settings-wrapper">
        <p-button
          v-if="!node.data.props.isEventSource && node.data.props.type !== 'finish'"
          variant="text"
          title="Manage step instructions"
          @click="setInstructions"
        >
          <md-icon size="md" name="account-question-outline" :color="hasInstructions ? 'success' : ''" />
        </p-button>
        <p-button
          v-if="!node.data.props.isEventSource && node.data.props.type !== 'finish'"
          class="toggle-button"
          :class="{ changed: changes.isCriticalDate }"
          variant="text"
          title="Mark step as critical"
          @click="changeCriticalDateState"
        >
          <md-icon size="sm" name="clock-alert-outline" :color="node.data.props.isCriticalDate ? 'error' : ''" />
        </p-button>
        <p-button
          v-if="!node.data.props.isEventSource && node.data.props.type !== 'finish'"
          class="toggle-button"
          :class="{ changed: changes.notifications }"
          variant="text"
          title="Manage step notification options"
          @click="showNotifications"
        >
          <md-icon size="sm" name="bell-ring" :color="bellColor"></md-icon>
        </p-button>

        <p-button variant="text" title="Toggle connections visibility" @click="toggleConnectionsVisibility">
          <md-icon size="sm" name="eye" :color="connectionsMode === 'show' ? 'success' : connectionsMode === 'hide' ? 'error' : ''"></md-icon>
        </p-button>

        <p-button
          v-if="!node.data.props.isEventSource && node.data.props.type !== 'finish'"
          variant="text"
          title="Add step to roadmap"
          @click="editRoadMapStage"
        >
          <md-icon size="sm" name="transit-connection-variant" :color="node.data.props.isAddedToRoadMap ? 'success' : ''"></md-icon>
        </p-button>

        <p-button
          v-if="!node.data.props.isEventSource && node.data.props.type !== 'finish'"
          class="toggle-button"
          :class="{ changed: changes.transitions }"
          variant="text"
          title="Edit step transitions"
          @click="showTransitions"
        >
          <p-icon size="md" name="settings"></p-icon>
        </p-button>
      </div>
      <div v-for="(input, index) in inputs()" :key="input.key" class="statuses">
        <div class="port-block">
          <p-socket v-socket:input="input" type="input" :socket="input.socket" :is-connected="input.connections.length" />
          <div class="button-wrapper">
            <p-button
              v-if="(input.connections.length && selected() && node.data.props.type !== 'finish') || hasInputStatusChanges(input.connections)"
              :class="hasInputStatusChanges(input.connections)"
              variant="text"
              title="Edit transition input options"
              @click="editStep(input.connections)"
            >
              &#9998;</p-button
            >
          </div>
        </div>
        <div class="text">
          <span></span>
          {{ input.name }}
          <span v-if="!node.data.props.isLoadingCount && node.data.props.tasksCount && node.data.props.tasksCount[input.name]" class="count">
            {{ node.data.props.tasksCount[input.name] > 99 ? '99+' : node.data.props.tasksCount[input.name] }}
          </span>
        </div>
        <div class="port-block">
          <div class="button-wrapper">
            <p-button
              v-if="(selected() && !node.data.props.isEventSource) || hasOutpuStatusChanges(outputs()[index].connections)"
              :class="hasOutpuStatusChanges(outputs()[index].connections)"
              variant="text"
              title="Edit transition form"
              @click="showForms(outputs()[index].connections, outputs()[index])"
            >
              &#9998;</p-button
            >
          </div>
          <p-socket
            v-socket:output="outputs()[index]"
            type="output"
            :socket="outputs()[index].socket"
            :is-connected="outputs()[index].connections.length"
          />
          <div v-if="hasForm(outputs()[index].connections)" class="form-mark"></div>
        </div>
      </div>
      <!-- <div>{{ node.data.props.changes }}</div> -->
    </div>
  </div>
</template>
<script>
import Plugin from 'rete-vue-render-plugin2';
import Socket from './Socket.vue';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button.vue';
import MdIcon from '@/components/common/MdIcon.vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    'p-socket': Socket,
    'p-text-field': TextField,
    'p-button': Button,
    MdIcon,
    'p-icon': Icon
  },
  mixins: [Plugin.mixin],
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    isEventSource: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEditingTitle: false,
      localTitle: this.node.data.props.title,
      isEditingDuration: false,
      localDuration: this.node.data.props.durationHours,
      connectionsMode: false
    };
  },
  computed: {
    isInCompareMode() {
      return !!this.node.data.props.compareMode;
    },
    hasInstructions() {
      return this.node && this.node.data.props.options.instructions && this.node.data.props.options.instructions.length;
    },
    bellColor() {
      if (!this.node.data.props.options) {
        return '';
      }
      if (this.node.data.props.options.notifications.email === 'always') {
        return 'success';
      }
      if (this.node.data.props.options.notifications.email === 'never') {
        return 'error';
      }

      return '';
    },
    canDelete() {
      if (this.node.data.props.isLoadingCount) {
        return false;
      }

      if (!this.node.data.props.miscellaneous) {
        return false;
      }
      const terminalStatuses = this.node.data.props.miscellaneous.task.terminalStatuses;

      const nonTeminalStatuses = (this.node.data.props.tasksCount ? Object.keys(this.node.data.props.tasksCount) : []).filter(
        (s) => !terminalStatuses.includes(s)
      );

      return nonTeminalStatuses.every((status) => {
        if (this.node.data.props.tasksCount[status] === undefined) {
          return true;
        }
        return this.node.data.props.tasksCount[status] === 0;
      });
    },
    changes() {
      const result = {};

      if (!this.node.data.props.changes || !this.node.data.props.changes.length) {
        return result;
      }
      return this.node.data.props.changes.reduce((acc, currentChange) => {
        acc[currentChange.fieldName] = {
          curr: currentChange.curr,
          next: currentChange.next
        };
        return acc;
      }, result);
    },
    hasInputStatusChanges() {
      return function (arr) {
        if (!this.isInCompareMode) {
          return;
        }

        const connections = arr.filter((connection) => connection.data.status && connection.data.status !== 'unchanged');
        const hasChanged = connections.some((c) => c.data.changes && c.data.changes.fieldName === 'to');
        return hasChanged && 'changed';
      };
    },
    hasOutpuStatusChanges() {
      return function (arr) {
        if (!this.isInCompareMode) {
          return;
        }
        const hasStatusChanges = arr.filter((connection) => connection.data?.status && connection.data.status !== 'unchanged');
        const hasChanged = hasStatusChanges.some((c) => c.data.changes && c.data.changes.fieldName === 'from');
        if (!hasChanged) {
          return;
        }

        const changes = hasStatusChanges[0].data.changes;
        if (changes) {
          if (changes.curr?.action && !changes.next?.action) {
            return 'deleted';
          }
          if (!changes.curr?.action && changes.next?.action) {
            return 'created';
          }
        }

        return 'changed';
      };
    }
  },
  mounted() {
    if (!this.editor.exist('connectionVisibilityChanged')) {
      this.editor.bind('connectionVisibilityChanged');
    }
    this.editor.on('connectionVisibilityChanged', ({ id }) => {
      if (id != this.node.id) {
        this.connectionsMode = false;
      }
    });
  },
  methods: {
    editDuration() {
      if (this.isInCompareMode) {
        return;
      }
      this.isEditingDuration = true;
    },
    onSave() {
      this.node.data.props.title = this.localTitle;
      this.isEditingTitle = false;
    },
    onCancel() {
      this.localTitle = this.node.data.props.title;
      this.isEditingTitle = false;
    },
    editStep(e) {
      this.editor.trigger('connectionCreatedSetup', e);
    },
    onNodeRemove(node) {
      this.editor.removeNode(node);
    },
    showTransitions() {
      this.editor.trigger('transitionsSetup', this.node.data.props);
    },
    showForms(e, output) {
      if (!e.length) {
        this.editor.trigger('createNowhereConnection', output);
        return;
      }
      this.editor.trigger('formsSetup', e);
    },
    hasForm(connections) {
      return connections.some((c) => c.data.from);
    },
    onDurationChange() {
      this.isEditingDuration = false;
      if (this.localDuration !== this.node.data.props.durationHours) {
        this.editor.trigger('stepDurationChanged', { node: this.node, duration: this.localDuration });
      }
    },
    toggleConnectionsVisibility() {
      if (!this.connectionsMode) {
        this.connectionsMode = 'show';
      } else if (this.connectionsMode === 'show') {
        this.connectionsMode = 'hide';
      } else {
        this.connectionsMode = null;
      }
      this.editor.trigger('connectionVisibilityChanged', { id: this.node.id, value: this.connectionsMode });
    },
    editRoadMapStage() {
      this.editor.trigger('editRoadMapStage', { id: this.node.id, props: this.node.data.props });
    },
    showNotifications() {
      this.editor.trigger('showNotifications', { id: this.node.id, props: this.node.data.props });
    },
    changeCriticalDateState() {
      if (this.isInCompareMode) {
        return;
      }
      this.editor.trigger('criticalDateStateChanged', { node: this.node });
    },
    setInstructions() {
      this.editor.trigger('setInstructions', { node: this.node });
    }
  }
};
</script>
<style lang="scss" scoped>
.changesApplied,
.changed {
  // border-color: var(--theme-warning) !important;
  color: var(--theme-warning) !important;
}

.created {
  color: var(--theme-success) !important;
}

.deleted {
  color: var(--theme-error) !important;
}

.tile {
  border: 2px solid white;
  background-color: var(--theme-surface);
  border: 2px solid var(--theme-on-background);
  border-radius: 5px;

  &.created {
    border-color: var(--theme-success);
  }

  &.deleted {
    border-color: var(--theme-error);
  }

  &.changed {
    border-color: var(--theme-warning);
  }
  .toggle-button {
    &.changed {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--theme-warning);
        top: 0px;
        right: 5px;
      }
    }
  }

  .show-text-changed {
    font-size: 0.8rem;
    min-height: 45px;
    text-align: center;
    border-bottom: 1px solid var(--theme-on-background);
    span {
      padding: 0 !important;
      &.previous {
        text-decoration: line-through;
      }
    }
  }

  .duration {
    position: absolute;
    right: 5px;
    top: 0px;
    border: 2px solid white;
    border-bottom-width: 0;
    transform: translateY(-100%);
    background-color: var(--theme-surface);
    max-width: 140px;
    div {
      grid-gap: 0;
    }
  }

  .form-mark {
    width: 8px;
    height: 16px;
    position: absolute;
    right: -8px;
    background-color: var(--theme-primary);
  }

  &.event-source {
    .input {
      display: none;
    }
    .text-holder {
      flex: 1;
    }
    .buttons-holder {
      display: none;
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .loading-wrapper {
    position: absolute;
    right: 5px;
    animation: spin 2s linear infinite;
  }
  .title-text {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--theme-on-background);
    .text-holder {
      min-height: 45px;
      flex: 1;
      min-width: 165px;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        grid-gap: 0;
      }
    }
    .buttons-holder {
      width: 35px;
    }
  }
  .title {
    text-align: center;
    padding: 5px;
    max-width: 220px;
    font-size: 1rem;
    .for-board {
      display: block;
      span {
        padding: 10px 12px;
      }
    }
    .for-dock {
      display: none;
    }
  }
  .remove-button {
    position: absolute;
    right: -35px;
    top: 5px;
  }
  .statuses-wrapper {
    .settings-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .statuses {
      display: grid;
      grid-template-columns: min-content 1fr min-content;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin: 5px 0;
      height: 22px;

      .port-block {
        width: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        .button-wrapper {
          width: 30px;
        }
      }
      .text {
        font-size: 0.85rem;
        display: grid;
        width: 100%;
        grid-template-columns: 15px 1fr 15px;
        justify-content: space-between;
        .count {
          font-size: 0.6rem;
          background-color: var(--theme-primary);
          width: 15px;
          padding: 1px;
          height: 15px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.dock-item {
  .tile {
    .for-dock {
      display: block;
      padding: 0 10px;
      .task-description {
        font-size: 0.75rem;
        font-style: italic;
        white-space: break-spaces;
      }
    }
    .for-board {
      display: none;
    }
  }
}
</style>
