<template>
  <div class="milestone-wizard">
    <p-button @click="$emit('close')">Close</p-button>
    <form-wizard color="var(--theme-primary)" @on-complete="onComplete">
      <tab-content title="Template name" :before-change="() => !!title">
        <p-wizard-title v-model="title" />
      </tab-content>
      <tab-content title="Creation event" :before-change="() => !!code">
        <p-wizard-creation-event v-model="code" />
        <predicates v-if="code" v-model="predicates" :code="code && code.code" :source="code && code.source" />
      </tab-content>

      <tab-content title="Milestone title" :before-change="() => !!milestoneTitle">
        <p-wizard-milestone-title v-model="milestoneTitle" :source="code && code.source" :code="code && code.code" :placeholder="'Milestone title'" />
      </tab-content>
      <tab-content title="Dates">
        <p-wizard-due-at v-if="code" v-model="dueAt" :source="code && code.source" :code="code && code.code" label="Due At" />
        <p-wizard-due-at v-if="code" v-model="clientDueDate" :source="code && code.source" :code="code && code.code" label="Client Due Date" />
      </tab-content>
      <tab-content title="Assignees">
        <p-wizard-assignees v-if="code" v-model="assignees" :source="code && code.source" :code="code && code.code" />
      </tab-content>
      <tab-content title="Notes">
        <div v-for="(note, index) in notes" :key="index" class="note-wrapper">
          <p-wizard-milestone-title
            :value="note"
            :source="code && code.source"
            :code="code && code.code"
            :multiline="true"
            @input="$e => onChangeNotes($e, index)"
          />
          <p-button class="remove-notes-button" @click="onRemoveNote(index)"><md-icon name="close"/></p-button>
        </div>
        <p-button color="primary" @click="addNote">Add note</p-button>
      </tab-content>

      <span slot="title"></span>
      <p-button color="primary" slot="finish">Create milestone template</p-button>
      <p-button color="primary" slot="prev">Back</p-button>
      <p-button color="primary" slot="next">Next</p-button>
    </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import Button from '@/components/common/Button';

import Title from './wizard-parts/Title.vue';
import CreationEvent from './wizard-parts/CreationEvent.vue';
import MilestoneTitle from '../workflow-editor/parts/InputWithVariables.vue';
import DueAt from '../workflow-editor/parts/DueDateWithVariables.vue';
import Assignees from '../workflow-editor/parts/AssigneesWithVariables.vue';
import Predicates from './wizard-parts/Predicates.vue';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    FormWizard,
    TabContent,
    'p-button': Button,
    'p-wizard-title': Title,
    'p-wizard-creation-event': CreationEvent,
    'p-wizard-milestone-title': MilestoneTitle,
    'p-wizard-due-at': DueAt,
    'p-wizard-assignees': Assignees,
    Predicates,
    MdIcon
  },
  props: {
    milestone: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      title: 'Default template title',
      milestoneTitle: 'New milestone',
      code: null,
      dueAt: {
        date: null,
        amount: 1,
        measures: 'Days'
      },
      clientDueDate: {
        date: null,
        amount: 1,
        measures: 'Days'
      },
      assignees: [],
      predicates: [],
      notes: []
    };
  },
  methods: {
    validate() {
      return this.title && this.milestoneTitle;
    },
    async onComplete() {
      if (!this.validate()) {
        return false;
      }
      try {
        await this.$store.dispatch('milestones/create', {
          workflowId: this.$route.params.id,
          data: {
            title: this.title,
            events: [
              {
                type: 'create',
                code: this.code.code,
                predicates: this.predicates,
                action: {
                  dueAt: this.dueAt,
                  clientDueDate: this.clientDueDate,
                  assignees: this.assignees,
                  title: this.milestoneTitle,
                  notes: this.notes.filter(n => !!n)
                }
              }
            ]
          }
        });
        this.$emit('done');
      } catch (e) {
        this.$toast.error({
          title: 'Milestone creation failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    addNote() {
      this.notes.push('');
    },
    onRemoveNote(index) {
      this.notes.splice(index, 1);
    },
    onChangeNotes($e, index) {
      this.notes.splice(index, 1, $e);
    }
  }
};
</script>
<style lang="scss">
.vue-form-wizard .wizard-nav-pills > li > a .stepTitle {
  color: #fff;
}
</style>
<style lang="scss" scoped>
.due-at-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.note-wrapper {
  position: relative;
  .remove-notes-button {
    position: absolute;
    right: 0px;
    top: 0px;
  }
}
</style>
