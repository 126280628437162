<template>
  <div class="content">
    <div v-if="label" class="label">{{ label }}</div>
    <div class="due-at-wrapper">
      <p-multiselect
        :value="value.date"
        :disabled="disabled"
        label="Source"
        placeholder="Variable"
        :options="availableContracts"
        @input="e => onChange('date', e)"
      />
      <p-text-field
        :value="value.amount"
        :disabled="disabled"
        label="Amount"
        placeholder="Number of {measures} to add(substract)"
        type="number"
        @input="e => onChange('amount', e)"
      />
      <p-multiselect
        :value="value.measures"
        :disabled="disabled"
        placeholder=""
        :options="measuresOptions"
        label="Measures"
        @input="e => onChange('measures', e)"
      />
      <template v-if="setupLimits">
        <div class="double-input-wrapper">
          <p-text-field
            :value="setupLimits && limits && limits.days && limits.days.min"
            :disabled="disabled"
            label="Min date"
            placeholder="min date limit"
            type="number"
            @input="e => onLimitChange('minDate', e)"
          />
          <p-text-field
            :value="setupLimits && limits && limits.days && limits.days.max"
            :disabled="disabled"
            label="Max date"
            placeholder="max date limit"
            type="number"
            @input="e => onLimitChange('maxDate', e)"
          />
        </div>
        <div class="double-input-wrapper">
          <p-text-field
            :value="setupLimits && limits && limits.hours.min"
            :disabled="disabled"
            label="Min time"
            placeholder="Min time limit 0 to 24"
            type="number"
            @input="e => onLimitChange('minHours', e)"
          />
          <p-text-field
            :value="setupLimits && limits && limits.hours && limits.hours.max"
            :disabled="disabled"
            label="Max time"
            placeholder="Max time limit 0 to 24"
            type="number"
            @input="e => onLimitChange('maxHours', e)"
          />
        </div>
        <div class="input-wrapper">
          <p-multiselect
            :value="calendar && calendar.event && calendar.event.categories"
            placeholder="Type to add event category"
            :taggable="true"
            :multiple="true"
            label="Categories"
            @input="e => onCalendarChange('categories', e)"
          />
          <p-text-field
            v-if="type === 'datetime'"
            :value="calendar && calendar.event && calendar.event.duration"
            :disabled="disabled"
            label="Duration (hours)"
            placeholder="Duration in hours (integer or fractional)"
            type="number"
            @input="e => onCalendarChange('duration', e)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TextField from '@/components/common/TextField.vue';
import Multiselect from '@/components/common/Multiselect';

import { mapVariablesFromContracts } from './utils';

const measuresOptions = ['Days', 'Business Days', 'Weeks', 'Months', 'Years'];
export default {
  components: {
    'p-text-field': TextField,
    'p-multiselect': Multiselect
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        date: '',
        amount: 1,
        measures: 'Days'
      })
    },
    label: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    additionalOptions: {
      type: Array,
      default: () => []
    },
    excludeContracts: {
      type: Array,
      default: () => []
    },
    setupLimits: {
      type: Boolean
    },
    limits: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'date'
    },
    calendar: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      measuresOptions
    };
  },
  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts
    }),
    availableContracts: function() {
      const options = mapVariablesFromContracts(this.contracts, this.source, this.code, ['date', 'datetime'], undefined, this.excludeContracts);
      return ['{{now}}', ...options, ...this.additionalOptions];
    }
  },
  methods: {
    onChange(field, value) {
      this.$emit('input', {
        ...this.value,
        [field]: value
      });
    },
    onLimitChange(field, value) {
      const currentLimits = this.limits || {
        days: {
          min: null,
          max: null
        },
        hours: {
          min: null,
          max: null
        }
      };

      if (field === 'minDate') {
        currentLimits.days.min = value;
      }
      if (field === 'maxDate') {
        currentLimits.days.max = value;
      }
      if (field === 'minHours') {
        currentLimits.hours.min = value;
      }
      if (field === 'maxHours') {
        currentLimits.hours.max = value;
      }

      this.$emit('limitsChanged', currentLimits);
    },
    onCalendarChange(field, value) {
      const currentCalendar = this.calendar || {
        event: {
          duration: null,
          categories: []
        }
      };

      if (field === 'duration') {
        currentCalendar.event.duration = value;
      }
      if (field === 'categories') {
        currentCalendar.event.categories = value;
      }

      this.$emit('calendarChanged', currentCalendar);
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  margin-bottom: 20px;
  .label {
    margin-bottom: 10px;
  }
}

.due-at-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}
.double-input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.input-wrapper {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 5px;
}
</style>
