<template>
  <p-modal :visible="true">
    <span slot="title"> {{ action }} connection </span>
    <section class="add-step-modal">
      <div>
        <div v-if="localConnections.length > 1" class="tabs">
          <template v-for="(tab, index) in localConnections">
            <div :key="index" :class="{ active: activeTab === index }" class="tab" @click="activeTab = index">
              <span>
                {{ `${connections[tab.index].output.node.data.props.title}(${connections[tab.index].output.name})` }}
                <span v-if="tab.status === 'changed'">(!)</span>
              </span>

              <p-button variant="text" style="font-size: 2rem" @click.stop="deleteConnection(index)"><md-icon name="close" /></p-button>
            </div>
          </template>
        </div>
        <div class="row">
          <predicates
            :key="activeTab"
            v-model="localConnections[activeTab].predicates"
            :disabled="localConnections[activeTab].changes"
            :code="localConnections[activeTab].eventSource.code"
            :source="localConnections[activeTab].eventSource.source"
          />
        </div>
        <div class="row">
          <h2 class="title">Title:</h2>
          <p-input-variables
            v-if="!localConnections[activeTab].changes"
            :key="activeTab"
            v-model="localConnections[activeTab].title"
            :additional-options="simpleVariables"
            :source="source"
            :code="code"
            :placeholder="'Title of task created by this step'"
          />
          <div v-else-if="localConnections[activeTab].changes.title">
            <span class="strike-through">{{ localConnections[activeTab].changes.title.old }}</span>
            <span>{{ localConnections[activeTab].changes.title.new }}</span>
          </div>
          <div v-else>
            {{ localConnections[activeTab].title }}
          </div>
        </div>
        <div class="row">
          <p-wizard-due-at
            v-if="!localConnections[activeTab].changes"
            :key="activeTab"
            v-model="localConnections[activeTab].dueAt"
            :source="source"
            :code="code"
            label="Due At:"
            :additional-options="dateVariables"
          />
          <div v-else-if="localConnections[activeTab].changes.dueAt">
            <h2 class="title">Due at:</h2>

            <span
              v-if="localConnections[activeTab].changes.dueAt.old.date !== localConnections[activeTab].changes.dueAt.new.date"
              class="strike-through"
            >
              {{ localConnections[activeTab].changes.dueAt.old.date }}
            </span>
            <span>{{ localConnections[activeTab].changes.dueAt.new.date }}</span>

            <span
              v-if="localConnections[activeTab].changes.dueAt.old.amount !== localConnections[activeTab].changes.dueAt.new.amount"
              class="strike-through"
            >
              {{ localConnections[activeTab].changes.dueAt.old.amount || 0 }}
            </span>
            <span>+{{ localConnections[activeTab].changes.dueAt.new.amount || 0 }}</span>

            <span
              v-if="localConnections[activeTab].changes.dueAt.old.measures !== localConnections[activeTab].changes.dueAt.new.measures"
              class="strike-through"
            >
              {{ localConnections[activeTab].changes.dueAt.old.measures }}
            </span>
            <span>{{ localConnections[activeTab].changes.dueAt.new.measures }}</span>
          </div>
          <div v-else>
            <h2 class="title">Due at:</h2>

            {{ localConnections[activeTab].dueAt.date }} {{ localConnections[activeTab].dueAt.amount }}
            {{ localConnections[activeTab].dueAt.measures }}
          </div>
        </div>
        <div class="row">
          <h2 class="title">Assignees:</h2>
          <p-assignees-with-variables
            v-if="!localConnections[activeTab].changes"
            :key="activeTab"
            v-model="localConnections[activeTab].assignees"
            :additional-options="peopleVariables"
            :source="source"
            :code="code"
          />
          <div v-else-if="localConnections[activeTab].changes.assignees">
            <span class="strike-through">{{ localConnections[activeTab].changes.assignees.old.join(', ') }}</span>
            <span>{{ localConnections[activeTab].changes.assignees.new.join(', ') }}</span>
          </div>
          <div v-else>
            {{ localConnections[activeTab].assignees.length ? localConnections[activeTab].assignees.join(', ') : 'unassigned' }}
          </div>
        </div>
        <div class="row">
          <h2 class="title">Attachments:</h2>
          <p-input-variables
            v-if="!localConnections[activeTab].changes"
            :key="activeTab"
            v-model="localConnections[activeTab].attachments"
            :additional-options="attachmentsVariables"
            :source="source"
            :code="code"
            :data-type="'file'"
            :exclude-contracts="['uspto', 'invention', 'milestone', 'email']"
            :placeholder="'Files attached to this task'"
          />
          <div v-else-if="localConnections[activeTab].changes.attachments">
            <span class="strike-through">{{ localConnections[activeTab].changes.attachments.old }}</span>
            <span>{{ localConnections[activeTab].changes.attachments.new }}</span>
          </div>
          <div v-else>
            {{ localConnections[activeTab].attachments }}
          </div>
        </div>
        <div class="row">
          <h2 class="title">Attached emails:</h2>
          <Multiselect v-model="localConnections[activeTab].emailAttachments" :multiple="true" :options="emailVariables" />
        </div>
        <div class="row">
          <h2 class="title">Notes:</h2>
          <p-input-variables
            v-if="!localConnections[activeTab].changes"
            :key="activeTab"
            v-model="localConnections[activeTab].notes"
            :source="source"
            :multiline="true"
            :additional-options="simpleVariables"
            :code="code"
          />

          <div v-else-if="localConnections[activeTab].changes.notes">
            <span class="strike-through">{{ localConnections[activeTab].changes.notes.old }}</span>
            <span>{{ localConnections[activeTab].changes.notes.new }}</span>
          </div>
          <div v-else v-html="localConnections[activeTab].notes"></div>
        </div>
      </div>
    </section>
    <div slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">{{ action }} connection</p-button>
    </div>
  </p-modal>
</template>

<script>
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';
import InputWithVariables from './parts/InputWithVariables.vue';
import AssigneesWithVariables from './parts/AssigneesWithVariables.vue';
import DueDateWithVariables from './parts/DueDateWithVariables.vue';
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal';
import Predicates from '../workflow/wizard-parts/Predicates.vue';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    'p-button': Button,
    'p-wizard-due-at': DueDateWithVariables,
    'p-assignees-with-variables': AssigneesWithVariables,
    'p-input-variables': InputWithVariables,
    'p-modal': Modal,
    Predicates,
    MdIcon,
    Multiselect
  },
  props: {
    connections: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    },
    action: {
      type: String,
      validator: function (value) {
        return ['create', 'update'].indexOf(value) !== -1;
      },
      default: 'create'
    }
  },

  data() {
    return {
      localConnections: this.connections.map((connection, index) => {
        let changes;
        if (connection.data.status === 'changed' && connection.data.changes.fieldName === 'to') {
          const fieldChanges = connection.data.changes;
          if (fieldChanges) {
            changes = {};
            const { curr, next } = fieldChanges;

            if (curr.action.title !== next.action.title) {
              changes.title = {
                old: curr.action.title,
                new: next.action.title
              };
            }

            if (curr.action.notes !== next.action.notes) {
              changes.notes = {
                old: curr.action.notes,
                new: next.action.notes
              };
            }

            if (
              curr.action.assignees.length !== next.action.assignees.length ||
              !curr.action.assignees.every((a) => next.action.assignees.includes(a))
            ) {
              changes.assignees = {
                old: curr.action.assignees,
                new: next.action.assignees
              };
            }

            if (
              curr.action.attachments.length !== next.action.attachments.length ||
              !curr.action.attachments.every((a) => next.action.attachments.includes(a))
            ) {
              changes.attachments = {
                old: curr.action.attachments,
                new: next.action.attachments
              };
            }
            if (
              curr.action.dueAt.amount !== next.action.dueAt.amount ||
              curr.action.dueAt.date !== next.action.dueAt.date ||
              curr.action.dueAt.measures !== next.action.dueAt.measures
            ) {
              changes.dueAt = {
                old: curr.action.dueAt,
                new: next.action.dueAt
              };
            }
          }
        }
        return {
          index,
          changes,
          title: connection.data.to ? connection.data.to.title : '',
          notes: connection.data.to ? connection.data.to.notes : '',
          eventSource: connection.output.node.data.props.isEventSource
            ? {
                source: ['milestone-start', 'workflow-form', connection.output.node.data.props.source],
                code: ['workflow.milestone.created', 'workflow.next', connection.output.name]
              }
            : {
                source: ['workflow', 'workflow-form'],
                code: ['workflow.next', 'workflow.next']
              },
          form: connection.data.from,
          predicates: (connection.data.to && connection.data.to.predicates) || [],
          dueAt: {
            date: connection.data.to ? connection.data.to.dueAt.date : '',
            amount: connection.data.to ? connection.data.to.dueAt.amount : 0,
            measures: connection.data.to ? connection.data.to.dueAt.measures : 'Days'
          },
          assignees: connection.data.to ? connection.data.to.assignees : [],
          attachments: connection.data.to ? connection.data.to.attachments : [],
          emailAttachments: connection.data.to ? connection.data.to.emailAttachments : [],
          status: connection.data.status
        };
      }),
      activeTab: 0
    };
  },

  computed: {
    ...mapState({
      contracts: (s) => s.milestones.contracts,
      milestone: (s) => s.milestones.item
    }),
    source() {
      return this.connections[this.activeTab].output.node.data.props.source;
    },
    code() {
      return this.connections[this.activeTab].output.name;
    },
    currentFormFields() {
      if (this.localConnections[this.activeTab].form) {
        return this.localConnections[this.activeTab].form.properties;
      }
      return [];
    },
    peopleVariables() {
      const people = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'people') : [];
      const formPeople = this.currentFormFields.filter((v) => v.type === 'people').map((v) => `{{form.${v.property}}}`);
      return ['{{thisTask.assignees}}', '{{thisTask.assignees.assistants}}', ...people.map((d) => d.saveTo), ...formPeople];
    },
    attachmentsVariables() {
      const files = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'file') : [];
      const formFiles = this.currentFormFields.filter((v) => v.type === 'file').map((v) => `{{form.${v.property}}}`);
      const resp = {
        thisTask: ['{{thisTask.attachments}}']
      };
      if (files?.length) {
        resp['milestone.variables'] = files.map((d) => d.saveTo);
      }
      if (formFiles?.length) {
        resp['form'] = formFiles;
      }
      return resp;
    },
    simpleVariables() {
      const resp = {
        thisTask: ['{{thisTask.notes}}', '{{thisTask.title}}', '{{thisTask.dueAt}}', '{{thisTask.assignees}}']
      };
      const vars = this.milestone.variables ? this.milestone.variables : [];
      if (vars) {
        resp['milestone.variables'] = vars.map((d) => d.saveTo);
      }
      if (this.currentFormFields.length) {
        resp['form'] = this.currentFormFields.map((v) => `{{form.${v.property}}}`);
      }
      return resp;
    },
    dateVariables() {
      const dates = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'date') : [];

      const formDates = this.currentFormFields.filter((v) => v.type === 'date' || v.type === 'datetime').map((v) => `{{form.${v.property}}}`);
      return ['{{thisTask.dueAt}}', ...dates.map((d) => d.saveTo), ...formDates];
    },
    emailVariables() {
      const files = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'email') : [];
      const formFiles = this.currentFormFields.filter((v) => v.type === 'email').map((v) => `{{form.${v.property}}}`);
      const resp = ['{{thisTask.emailAttachments}}'];

      if (files?.length) {
        resp.push(...files.map((d) => d.saveTo));
      }
      if (formFiles?.length) {
        resp.push(...formFiles);
      }
      if (this.connections[this.activeTab].output.key === 'correspondence.recieved____output') {
        resp.push('{{email}}');
      }
      return resp;
    }
  },
  methods: {
    onConfirm() {
      this.$emit(
        'confirm',
        this.localConnections.map(({ form, ...other }) => ({ ...other }))
      );
    },
    deleteConnection(index) {
      this.localConnections.splice(index, 1);
      this.activeTab = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.strike-through {
  text-decoration: line-through;
}
.add-step-modal {
  padding: 20px;
  header {
    h2 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 1.75rem 2rem 0.75rem 2rem;
    }
  }
  .row {
    margin-bottom: 20px;
    border: 1px solid #fff;
    padding: 20px;
    .title {
      margin-bottom: 10px;
    }
  }
  .tabs {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .tab {
      padding: 10px;
      flex: 1;
      border: 1px solid #fff;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.active {
        border-bottom-width: 0;
        cursor: default;
      }
    }
  }
}
</style>
