<template>
  <div class="multiselect-wrapper">
    <multiselect
      :id="id"
      :value="value"
      :options="options"
      :multiple="false"
      :searchable="false"
      :taggable="false"
      :show-no-results="false"
      :hide-selected="false"
      :show-labels="false"
      :show-no-options="false"
      :custom-label="getLabelCallback"
      :disabled="false"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <template slot="singleLabel" slot-scope="props">
        <span
          v-if="props.option.type"
          :class="{
            offline: props.option.type === 'template' && !props.option.active,
            online: props.option.type === 'template' && props.option.active,
            draft: props.option.type === 'draft' || props.option.isDraft
          }"
        >
          {{ getStatusLabel(props.option) }}</span
        >
        <span>{{
          new Date(props.option.updatedAt || props.option.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
        }}</span>
        <span class="time">{{ ` at ${new Date(props.option.updatedAt || props.option.createdAt).toLocaleTimeString('en-US')}` }}</span>
      </template>
      <template slot="option" slot-scope="props">
        <div class="parent">
          <div class="div1">
            <span
              :class="{
                offline: props.option.type === 'template' && !props.option.active,
                online: props.option.type === 'template' && props.option.active,
                draft: props.option.type === 'draft' || props.option.isDraft
              }"
              >{{ getStatusLabel(props.option) }}</span
            >
          </div>
          <div class="div2">
            <span>{{
              new Date(props.option.updatedAt || props.option.createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
            }}</span>
            <span class="time">{{ ` at ${new Date(props.option.updatedAt || props.option.createdAt).toLocaleTimeString('en-US')}` }}</span>
          </div>
          <div class="div3">
            <span class="time">{{ `by ${props.option.author.split('@')[0]}` }}</span>
          </div>
          <div class="div4">
            <span v-if="props.option.commitMessage">({{ props.option.commitMessage }})</span>
          </div>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },
  props: {
    id: {
      type: String,
      default: () => (+new Date()).toString()
    },
    value: {
      type: [String, Array, Object],
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    getLabelCallback: {
      type: Function,
      default: (s) => s
    }
  },
  data() {
    return {
      optionsAsync: [...this.options]
    };
  },
  methods: {
    getStatusLabel(value) {
      if (value.type === 'draft' || (value.type === 'template' && value.isDraft)) {
        return 'DRAFT:';
      }

      if (value.type === 'template') {
        return value.active ? 'ONLINE:' : 'OFFLINE:';
      }
    }
  }
};
</script>

<style lang="scss">
.has-label {
  grid-gap: 5px;
}
.multiselect-wrapper {
  display: grid;
  grid-template-rows: max-content max-content;
  > label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .multiselect {
    box-sizing: border-box;

    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-dark);
    color: var(--theme-on-background-dark);
    background: var(--theme-background);
    cursor: default;
    border-collapse: separate;
    min-height: 32px;
    position: relative;
    outline: none;

    &.multiselect--active {
      border-color: var(--theme-primary);
      .multiselect__content-wrapper {
        border-color: var(--theme-primary);
      }
      .multiselect__input {
        width: 100% !important;
        visibility: visible !important;
      }
    }
    .multiselect__select {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 0px;
      top: 0px;
      padding: 0;
      text-align: center;
      transition: transform 0.2s ease;

      &:before {
        position: relative;
        right: 0;
        top: 65%;
        color: rgb(153, 153, 153);
        margin-top: 4px;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: rgb(153, 153, 153) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        content: '';
      }
    }
    .multiselect__content-wrapper {
      border-top-width: 0;
      left: -1px;
      right: -1px;
      // margin-top: -2px;
      width: auto;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      position: absolute;
      border: 1px solid var(--theme-on-background-dark);
      border-top-width: 0;
      color: var(--theme-on-background-dark);
      background: var(--theme-background);
      z-index: 1;
      overflow-y: auto;
      ul {
        width: 100%;
      }
      .multiselect__content {
        display: block !important;
        .multiselect__element,
        li {
          display: block;
          .multiselect__option {
            padding: 8px 12px;
            font-family: inherit;
            font-size: 0.85rem;
            font-weight: 400;
            white-space: initial;
            display: block;
            color: var(--theme-on-background);
            background: var(--theme-background);
            &:after {
              top: 0;
              right: 0;
              position: absolute;
              padding-right: 12px;
              padding-left: 20px;
              font-family: inherit;
              font-size: 0.85rem;
              font-weight: 400;
              color: var(--theme-on-background);
              background: var(--theme-background);
              line-height: 26px;
            }

            &.multiselect__option--highlight {
              cursor: pointer;
              background: var(--theme-surface);
              &:after {
                color: var(--theme-on-surface);
                background: var(--theme-surface);
                content: attr(data-select);
              }
            }
          }
        }
      }
    }
    &.multiselect--above {
      .multiselect__content-wrapper {
        border-bottom-width: 0;
        border-top-width: 1px;
        bottom: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }

    .multiselect__element > .multiselect__option > span:empty:before {
      content: '';
      display: inline-block;
    }

    .multiselect--disabled {
      background: transparent;
    }

    .multiselect--disabled .multiselect__select {
      background: transparent;
    }

    .multiselect--disabled .multiselect__tags {
      opacity: 0.7;
    }

    .multiselect--disabled input[disabled] {
      background: transparent;
    }

    .time {
      color: var(--theme-on-background-dark);
    }
    .online {
      color: var(--theme-primary);
      padding-right: 10px;
    }
    .offline {
      color: var(--theme-error);
      padding-right: 3px;
    }

    .draft {
      color: var(--theme-warning);
      padding-right: 14px;
    }
  }

  .parent {
    display: grid;
    grid-template-columns: minmax(60px, min-content);
  }

  .div1 {
    grid-area: 1 / 1 / 4 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div4 {
    grid-area: 3 / 2 / 4 / 3;
  }
}
</style>
