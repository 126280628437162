<template>
  <div class="wrapper">
    <component :is="'style'" type="text/css">{{ eventSourcesStyles }}</component>
    <component :is="'style'" type="text/css">{{ hiddenPathsStyles }}</component>
    <component :is="'style'" type="text/css">{{ shownPathStyles }}</component>
    <component :is="'style'" type="text/css">{{ compareModeStyles }}</component>
    <div v-if="template" class="buttons-panel">
      <div class="label">
        <p-button variant="text" color="secondary" @click="goBack">BACK</p-button>
        <span>/</span>
        <span>{{ workflowTitle }}</span>
        <span>/</span>
        <span>{{ template.title }}</span>
        <span></span>
        <span v-if="template.type === 'draft' || (milestone && milestone.isDraft)" class="draft">DRAFT</span>
        <span
          v-else-if="template.type === 'template' && milestone && !milestone.isDraft"
          :class="template.active ? 'on-line' : 'off-line'"
          :title="
            template.active
              ? 'This template produces new milestones automatically'
              : `This template was deactivated and does't produce new milestones automatically. All milestones created from this template continue functioning.`
          "
          @click="onActivateDeactivate"
          >{{ template.active ? 'ONLINE' : 'OFFLINE' }}</span
        >
        <span v-else class="revision-title">{{ getRevisionLabel(template) }}</span>
        <div>
          <p-button v-if="$hasPermission('workflows.read')" variant="text" title="Export template" style="font-size: 1.5rem" @click="exportTemplate">
            <md-icon size="md" name="application-export" />
          </p-button>
          <p-button
            v-if="$hasPermission('workflows') && milestone && !milestone.isDraft"
            variant="text"
            title="Delete this template"
            style="font-size: 1.5rem"
            @click="deleteTemplate"
          >
            <md-icon size="md" name="delete-alert" />
          </p-button>
        </div>
      </div>
      <div class="buttons-wrapper">
        <div v-if="!compareMode" class="action-buttons">
          <p-button v-if="$hasPermission('workflows') && (hasChanges || template.type === 'revision')" color="secondary" @click="saveDraft">{{
            template.type === 'draft' || (milestone && milestone.isDraft) ? 'Save' : 'Save as draft'
          }}</p-button>
          <p-button
            v-if="($hasPermission('workflows') && template && template.type === 'draft') || (milestone && milestone.isDraft)"
            color="secondary"
            @click="discardDraft"
            >Discard draft</p-button
          >
          <p-button variant="text" class="variables" @click="toggleVariablesPopup">Variables</p-button>
          <p-button variant="text" @click="toggleRoadmapPopup">
            {{ template && template.roadmap && template.roadmap.length ? 'Edit' : 'Define' }} roadmap
          </p-button>
          <p-button v-if="$hasPermission('workflows.read')" variant="text" @click="togglePropertiesPopup">Properties</p-button>
          <p-button v-if="template.type !== 'template'" variant="text" @click="compare"> Compare with current </p-button>
          <roadmap v-if="template && template.roadmap && template.roadmap.length" :roadmap="template.roadmap" />
        </div>
        <div v-else class="action-buttons">
          <p-button variant="text" @click="cancelComparing"> Quit comparision mode </p-button>
        </div>
        <div class="paths">
          <span v-if="shortestDistance != null">Shortest planned duration: {{ shortestDistance }} hours</span>
          <span v-else>Path does not exists</span>
        </div>
        <div>
          <div class="activation-buttons">
            <p-button v-if="showActivateOption" color="primary" @click="saveAndActivate">Save & Activate</p-button>
            <p-button v-if="$hasPermission('workflows') && template.type === 'revision' && !hasChanges" color="primary" @click="activateRevision"
              >Activate</p-button
            >
          </div>
          <div class="multiselect">
            <p-multiselect
              v-if="!isImporting && !compareMode && templateHistory && templateHistory.length"
              :value="template"
              :options="templateHistory"
              @input="onRevisionChange"
            />
          </div>
        </div>
      </div>
      <div class="filter">
        <span class="title">Show connections: </span>
        <div class="checkbox-wrapper">
          <p-checkbox v-model="showConnection.workflow" class="check-box" label="Workflow" />
        </div>
        <div class="checkbox-wrapper">
          <p-checkbox v-model="showConnection.eventSource" class="check-box" label="Event Sources" />
        </div>
      </div>
    </div>

    <div class="dock"></div>
    <div ref="wrapper" :class="{ 'hide-eventSources': !showConnection.eventSource, 'hide-workflow': !showConnection.workflow }" class="wrp"></div>
    <variables-modal v-if="variablesPopupShown" :template="template" @close="toggleVariablesPopup" />
    <roadmap-modal
      v-if="roadmapModalShown"
      :roadmap="template && template.roadmap"
      :steps="allSteps"
      @close="toggleRoadmapPopup"
      @save="saveRoadmap"
    />
    <milestone-edit-popup v-if="propertiesPopupShown" :milestone="template" @close="togglePropertiesPopup" />
    <CommitModal v-if="showCommitModal" :draft="commitForDraft" @close="toggleCommitModal" @activate="onActivate" @saveDraft="onSaveDraft" />
  </div>
</template>
<script>
import Rete from 'rete';
import { mapState, mapGetters } from 'vuex';

import createComponents from './utils/createComponents';
import registerPlugins from './utils/registerPlugins';
import addSubscriptions from './utils/addSubscriptions';
import payload from './utils/payload';
import CheckBox from '@/components/common/Checkbox';
import { dijkstra } from './utils/dijkstra';
import Button from '@/components/common/Button';
import VariablesModal from './VariablesModal.vue';
import RoadmapModal from './RoadmapModal.vue';
import Roadmap from './Roadmap.vue';
import { zoomAt } from './utils/zoom';
import Multiselect from '@/components/common/RevisionMultiselect.vue';
import MdIcon from '@/components/common/MdIcon';
import MilestoneEditPopup from '../workflow/MilestoneEditPopup.vue';
import CommitModal from './CommitModal.vue';
import httpClient from '../../utils/httpClient';

export default {
  components: {
    'p-checkbox': CheckBox,
    'p-button': Button,
    VariablesModal,
    RoadmapModal,
    Roadmap,
    'p-multiselect': Multiselect,
    MdIcon,
    MilestoneEditPopup,
    CommitModal
  },
  data() {
    return {
      editor: null,
      variablesPopupShown: false,
      propertiesPopupShown: false,
      addedEventSources: [],
      showConnection: {
        eventSource: true,
        workflow: true
      },
      hiddenPaths: [],
      shortestDistance: 0,
      roadmapModalShown: false,
      hasChanges: false,
      hasDraft: false,
      isImporting: false,
      showActivate: false,
      storeSubscription: null,
      shownPath: null,
      showCommitModal: false,
      commitForDraft: false,
      compareMode: false,
      compareResult: null
    };
  },
  computed: {
    ...mapState({
      milestone: (s) => s.milestones.item,
      template: (s) => s.milestones.editedTemplate,
      draft: (s) => s.milestones.draft,
      revisions: (s) => s.milestones.revisions?.sort((a, b) => b.revision - a.revision),
      eventSources: (s) => s.milestones.contracts.eventSources,
      miscellaneous: (s) => s.milestones.contracts.miscellaneous,
      steps: (s) => s.milestones.templates.steps
    }),
    ...mapGetters({
      templateHistory: 'milestones/templateHistory'
    }),
    stepTypes: function() {
      return Object.values(this.steps).map(step => step.type);
    },
    typesMap: function() {
      return this.steps.reduce((acc, curr) => {
        acc[curr.type] = curr.ports;
        return acc;
      }, {});
    },
    eventSourcesStrings() {
      return this.eventSources.map(e => e.source);
    },
    eventSourcesStyles() {
      return this.addedEventSources.map(s => `.wrapper .dock .dock-item .event-source.${s} { display: none; }`).join(' ');
    },
    hiddenPathsStyles() {
      return this.hiddenPaths
        .map(
          s => `.connection.input-${s},
          .connection.input-${s} + div,
          .connection.output-${s},
          .connection.output-${s} + div { display: none;  }`
        )
        .join(' ');
    },
    compareModeStyles() {
      if (!this.compareMode || !this.compareResult) {
        return '';
      }
      const deletedPaths = this.compareResult.paths.filter((p) => p.status === 'deleted');
      const createdPaths = this.compareResult.paths.filter((p) => p.status === 'created');

      const deletedPathsStyles = deletedPaths.map(({ curr }) => {
        return `.input-${curr.to.step}.output-${curr.from.step}.input-${curr.to.port.replace(' ', '_')}.output-${curr.from.port.replace(
          ' ',
          '_'
        )} path { stroke: var(--theme-error); }`;
      });

      const createdPathsStyles = createdPaths.map(({ next }) => {
        return `.input-${next.to.step}.output-${next.from.step}.input-${next.to.port.replace(' ', '_')}.output-${next.from.port.replace(
          ' ',
          '_'
        )} path { stroke: var(--theme-success); }`;
      });

      return `${deletedPathsStyles} ${createdPathsStyles}`;
    },
    shownPathStyles() {
      if (!this.shownPath) {
        return '';
      }
      return `.connection.input-${this.shownPath},
          .connection.input-${this.shownPath} + div,
          .connection.output-${this.shownPath},
          .connection.output-${this.shownPath} + div { display: block!important;  }`;
    },
    allSteps() {
      const data = this.editor.toJSON();
      return Object.values(data.nodes).map(p => ({ id: p.id, title: p.data && p.data.props.title }));
    },
    workflowTitle() {
      return this.$store.state.workflows.collection?.find(wf => wf.id === this.$route.params.workflowId)?.title;
    },
    showActivateOption() {
      if (!this.template) {
        return false;
      }
      if (!this.$hasPermission('workflows')) {
        return false;
      }

      if (this.template.type === 'revision') {
        return false;
      }

      if (this.template.type === 'template') {
        return this.template.isDraft || this.hasChanges;
      }

      return this.template.isDraft || this.hasDraft;
    }
  },
  watch: {
    'template.id': async function() {
      if (!this.template) {
        return;
      }

      this.isImporting = true;
      this.editor.clear();

      const data = payload.importData(this.template, this.eventSources, this.typesMap);
      await this.editor.fromJSON(data);

      this.hasChanges = false;
      this.showActivate = false;

      if (this.template.id === this.milestone.id) {
        this.showActivate = false;
      } else if (this.template.id === this.draft?.id) {
        this.showActivate = false;
      } else {
        this.showActivate = true;
      }

      this.isImporting = false;
      this.calculateDistances();
      zoomAt(this.editor);
      await this.fillCountObjects();
    }
  },
  async created() {
    await this.$store.dispatch('workflows/getCollection');
  },
  async mounted() {
    this.hasDraft = this.draft ? true : false;
    this.$store.commit('milestones/SET_ACTIVE_TEMPLATE', this.draft ? 'draft' : 'template');

    const container = this.$refs.wrapper;
    this.editor = new Rete.NodeEditor('demo@0.1.0', container);

    registerPlugins(this.editor);
    const start = this.eventSources.find(source => source.source === 'milestone-start');

    createComponents(this.editor, this.eventSources, this.steps, start.source, this.miscellaneous);

    this.editor.on('renderconnection', ({ connection, el }) => {
      el.children[0].classList.add(`event-source-${connection.output.node.data.props.source}`);
      el.children[0].classList.add(`input-${connection.input.node.id}`);
      el.children[0].classList.add(`output-${connection.output.node.id}`);

      el.children[0].classList.add(`input-${connection.input.name.split(' ').join('_')}`);
      el.children[0].classList.add(`output-${connection.output.name.split(' ').join('_')}`);
      if (connection.output.node.data.props.isEventSource) {
        el.children[0].classList.add('event-source');
      }
    });

    this.editor.on('noderemove', node => {
      const index = this.addedEventSources.findIndex(s => s === node.name);
      if (index > -1) {
        this.addedEventSources.splice(index, 1);
      }
    });

    this.editor.on('nodecreate', node => {
      if (this.eventSourcesStrings.includes(node.name)) {
        this.addedEventSources.push(node.name);
      }
    });

    if (!this.editor.exist('connectionVisibilityChanged')) {
      this.editor.bind('connectionVisibilityChanged');
    }
    this.editor.on('connectionVisibilityChanged', ({ id, value }) => {
      this.shownPath = null;
      if (!value) {
        this.hiddenPaths = [];
      }
      if (value === 'show') {
        const data = this.editor.toJSON();
        const allNodesIds = Object.keys(data.nodes);
        this.hiddenPaths = allNodesIds.filter(nodeId => nodeId != id);
        this.shownPath = id;
      }
      if (value === 'hide') {
        this.hiddenPaths = [id];
      }
    });

    addSubscriptions(this.editor, this);

    this.storeSubscription = this.$store.subscribe(mutation => {
      if (mutation.type === 'milestones/VARIABLES_CHANGED' || mutation.type === 'milestones/PROPERTIES_CHANGED') {
        this.hasChanges = true;
      }
    });
    this.editor.on('stepDurationChanged nodecreated connectioncreated noderemoved connectionremoved', () => {
      if (!this.compareMode) {
        this.calculateDistances();
      }
    });
  },
  beforeDestroy() {
    this.$store.commit('milestones/SET_ACTIVE_TEMPLATE', 'none');
    this.storeSubscription && this.storeSubscription();
  },

  methods: {
    calculateDistances() {
      try {
        const { distances } = dijkstra(this.graphRepresentation(), 'start');
        this.shortestDistance = distances.finish === Infinity ? undefined : distances.finish.toFixed(1);
      } catch {}
    },
    graphRepresentation() {
      if (this.compareMode) {
        return [];
      }
      if (!this.editor) {
        return [];
      }
      const data = this.editor.toJSON();
      if (!data.nodes) {
        return [];
      }
      // const createEvent = this.milestone.events.find(e => e.type === 'create');
      const start = this.eventSources.find(source => source.source === 'milestone-start');

      const entries = Object.entries(data.nodes).filter(node => node[0] !== 'nowhere');
      const finish = entries.find(e => e[1].name === 'finish');
      return entries.reduce((acc, node) => {
        let key = node[0];
        if (node[1].name === start.source) {
          key = 'start';
        }
        if (node[1].name === 'finish') {
          key = 'finish';
        }

        if (!acc[key]) {
          acc[key] = {};
        }

        Object.values(node[1].outputs)
          .filter(o => o.connections.length)
          .forEach(output => {
            output.connections.forEach(connection => {
              acc[key][connection.node === finish[1].id ? 'finish' : connection.node] = node[1].data.props.durationHours
                ? +node[1].data.props.durationHours
                : 0.0;
            });
          });
        return acc;
      }, {});
    },
    async discardDraft() {
      try {
        const confirmResult = await this.$confirm({
          title: `Discard draft`,
          message: `Are you sure you want to DISCARD existing draft?`,
          confirm: 'Discard'
        });

        if (!confirmResult) {
          return;
        }

        if (this.milestone.isDraft) {
          await this.$store.dispatch('milestones/deleteDraft', { draftId: this.milestone.id });
          this.$toast.success({
            message: `Draft deleted`
          });
          this.$router.push(`/workflows/${this.$route.params.workflowId}`);
        } else {
          await this.$store.dispatch('milestones/discardDraft', { draftId: this.milestone.id });
          this.$toast.success({
            message: `Draft discarded`
          });
        }

        this.$store.commit('milestones/SET_ACTIVE_TEMPLATE', 'template');
      } catch (e) {
        this.$toast.error({
          title: 'Delete draft failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async saveDraft() {
      if (!this.milestone.isDraft && this.template.type !== 'draft' && this.templateHistory.find(t => t.type === 'draft')) {
        const confirmResult = await this.$confirm({
          title: `Save draft`,
          message: `Are you sure you want to overwrite existing draft?`,
          confirm: 'Save'
        });

        if (!confirmResult) {
          return;
        }
      }
      this.commitForDraft = true;
      this.toggleCommitModal();
    },
    async onSaveDraft(commitMessage) {
      this.commitForDraft = false;
      this.toggleCommitModal();

      const editorData = this.editor.toJSON();
      const { steps, paths, eventSources } = payload.exportData(editorData, this.stepTypes);

      try {
        if (this.milestone.isDraft) {
          await this.$store.dispatch('milestones/upsertDraft', {
            workflowId: this.$route.params.workflowId,
            draftId: this.milestone.id,
            data: { ...this.template, steps, paths, eventSources, commitMessage }
          });
        } else {
          await this.$store.dispatch('milestones/upsertDraftForTemplate', {
            workflowId: this.$route.params.workflowId,
            milestoneId: this.milestone.id,
            data: { ...this.template, steps, paths, eventSources, commitMessage }
          });
        }

        if (!this.milestone.isDraft && this.template.type !== 'draft') {
          this.$store.commit('milestones/SET_ACTIVE_TEMPLATE', 'draft');
        }

        this.$toast.success({
          message: `Draft saved`
        });
        this.hasDraft = true;
        this.hasChanges = false;
      } catch (e) {
        this.$toast.error({
          title: 'Update draft failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async onActivate(commitMessage) {
      this.showCommitModal = false;
      const editorData = this.editor.toJSON();
      const { steps, paths, eventSources } = payload.exportData(editorData, this.stepTypes);
      try {
        this.$store.commit('milestones/SET_ACTIVE_HAS_DRAFT');
        await this.$store.dispatch('milestones/update', {
          workflowId: this.$route.params.workflowId,
          milestoneId: this.milestone.id,
          data: { ...this.template, steps, paths, eventSources, commitMessage }
        });
        await this.$store.dispatch('milestones/activate', {
          workflowId: this.$route.params.workflowId,
          milestoneId: this.milestone.id
        });
        this.$toast.success({
          title: 'Update completed',
          message: `Milestone saved and activated`
        });
        this.hasChanges = false;
        this.hasDraft = false;
        this.$router.push(`/workflows/${this.$route.params.workflowId}`);
      } catch (e) {
        this.$toast.error({
          title: 'Update failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    saveAndActivate() {
      const distances = dijkstra(this.graphRepresentation(), 'start');
      if (distances.distances.finish === Infinity) {
        this.$toast.error({
          title: 'Error',
          message: `Finish node is unreachable`
        });
        return;
      }
      this.commitForDraft = false;
      this.toggleCommitModal();
    },
    async activateRevision() {
      const confirmResult = await this.$confirm({
        title: `Activate revision`,
        message: `Are you sure you want to ACTIVATE this revision?`,
        confirm: 'Activate'
      });

      if (!confirmResult) {
        return;
      }

      const editorData = this.editor.toJSON();
      const { steps, paths, eventSources } = payload.exportData(editorData, this.stepTypes);
      await this.$store.dispatch('milestones/activateRevision', {
        workflowId: this.$route.params.workflowId,
        milestoneId: this.milestone.id,
        data: { ...this.template, steps, paths, eventSources }
      });
      this.$router.push(`/workflows/${this.$route.params.workflowId}`);
      this.$toast.success({
        title: 'Template activated',
        message: `Milestone template activated`
      });
    },
    async onActivateDeactivate() {
      const action = this.template.active ? 'Deactivate' : 'Activate';
      const message = this.template.active
        ? `Are you sure you want to deactivate '${this.template.title.toUpperCase()}'?\r\n\r\nDeactivating this tempate will suspend automatic milestone creation.`
        : `Are you sure you want to activate '${this.template.title.toUpperCase()}'?\r\n\r\nActivating this tempate will resume automatic milestone creation.`;
      const confirmResult = await this.$confirm({
        title: `${action} template`,
        message: message,
        confirm: action
      });

      if (!confirmResult) {
        return;
      }

      if (this.template.active) {
        await this.$store.dispatch('milestones/deactivate', {
          workflowId: this.$route.params.id,
          milestoneId: this.milestone.id
        });
      } else {
        await this.$store.dispatch('milestones/activate', {
          workflowId: this.$route.params.id,
          milestoneId: this.milestone.id
        });
      }

      this.$toast.success({
        message: `'${this.template.title}' ${this.template.active ? 'activated' : 'deactivated'}`
      });
    },
    async goBack() {
      const editorData = this.editor.toJSON();
      const { steps, paths, eventSources } = payload.exportData(editorData, this.stepTypes);
      const stepsHasChanges = JSON.stringify(this.template.steps) !== JSON.stringify(steps);
      const pathsHasChanges = JSON.stringify(this.template.paths) !== JSON.stringify(paths);
      const eventSourcesHasChanges = JSON.stringify(this.template.eventSources) !== JSON.stringify(eventSources);
      if (this.hasChanges && (stepsHasChanges || pathsHasChanges || eventSourcesHasChanges)) {
        const confirmResult = await this.$confirm({
          title: 'You have unsaved changes.',
          message: `Are you sure you want to leave page? All changes will be lost.`,
          confirm: 'Leave anyway'
        });
        if (!confirmResult) {
          return;
        }
      }
      this.$router.push(`/workflows/${this.$route.params.workflowId}`);
    },
    toggleVariablesPopup() {
      this.variablesPopupShown = !this.variablesPopupShown;
    },
    toggleCommitModal() {
      this.showCommitModal = !this.showCommitModal;
    },
    toggleRoadmapPopup() {
      this.roadmapModalShown = !this.roadmapModalShown;
    },
    saveRoadmap(roadmap) {
      this.roadmapModalShown = false;
      const allInserted = roadmap.reduce((acc, curr) => {
        acc.push(...curr.steps);
        return acc;
      }, []);
      this.editor.nodes.forEach(node => {
        node.data.props.isAddedToRoadMap = allInserted.includes(node.id);
      });
      this.hasChanges = true;
      this.$store.commit('milestones/SET_ACTIVE_ROADMAP', roadmap);
    },
    async fillCountObjects() {
      const promises = [];
      this.editor.nodes.forEach(node => {
        if (node.data.props.isLoadingCount) {
          promises.push(this.getTaskCount(node));
        }
      });
      await Promise.all(promises);
    },
    async getTaskCount(node) {
      const { workflowId, milestoneId } = this.$route.params;

      const counts = await this.$store.dispatch('milestones/getStepTasksCount', { workflowId, milestoneId, stepId: node.id });
      counts.forEach(count => {
        node.data.props.tasksCount[count.status] = count.count;
      });
      node.data.props.isLoadingCount = false;
    },
    async onRevisionChange(value) {
      this.$store.commit('milestones/SET_ACTIVE_TEMPLATE', value.type);
    },
    getRevisionLabel(value) {
      var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

      return `${new Date(value.updatedAt || value.createdAt).toLocaleDateString('en-US', options)}`;
    },
    async exportTemplate() {
      const editorData = this.editor.toJSON();
      const { steps, paths, eventSources } = payload.exportData(editorData, this.stepTypes);
      const { title, events, type, roadmap, variables } = this.template;
      const exportObject = {
        title,
        type,
        events,
        steps,
        paths,
        eventSources,
        ...(roadmap && { roadmap: roadmap }),
        ...(variables && { variables: variables })
      };
      await navigator.clipboard.writeText(JSON.stringify(exportObject));
      this.$toast.success({
        message: `Template is copied to clipboard`
      });
    },
    togglePropertiesPopup() {
      this.propertiesPopupShown = !this.propertiesPopupShown;
    },
    async deleteTemplate(event) {
      const confirmResult = await this.$confirm({
        title: 'Delete template?',
        message: `Are you sure you want to delete milestone template? You can deactivate it otherwise all in-progress milestones will be orphaned!`,
        confirm: 'Delete'
      });
      if (confirmResult) {
        try {
          await this.$store.dispatch('milestones/deleteTemplate', { id: this.milestone.id });
          this.$router.push(`/workflows/${this.$route.params.workflowId}`);
        } catch (e) {
          const { message } = JSON.parse(await e.response.text());
          this.$toast.error({
            title: 'Delete failed',
            message: message || `Please, try again later or contact our development team.`
          });
        }
      }
    },
    async compare() {
      this.compareMode = true;
      this.isImporting = true;

      const compareResult = await httpClient.post('/api/workflows/v2/workflows/compare', {
        current: this.milestone,
        next: this.template
      });
      this.compareResult = compareResult;
      const prepaireadData = payload.importCompairedData(compareResult, this.eventSources, this.typesMap);

      this.editor.clear();
      await this.editor.fromJSON(prepaireadData);
      this.isImporting = false;
      zoomAt(this.editor);
    },
    async cancelComparing() {
      this.compareResult = null;
      this.compareMode = false;
      this.isImporting = true;
      this.editor.clear();

      const data = payload.importData(this.template, this.eventSources, this.typesMap);
      await this.editor.fromJSON(data);

      this.hasChanges = false;
      this.showActivate = false;

      if (this.template.id === this.milestone.id) {
        this.showActivate = false;
      } else if (this.template.id === this.draft?.id) {
        this.showActivate = false;
      } else {
        this.showActivate = true;
      }

      this.isImporting = false;
      this.calculateDistances();
      zoomAt(this.editor);
      await this.fillCountObjects();
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
}

.buttons-panel {
  margin: 10px 0;
  padding: 0 10px;
  height: 60px;
  user-select: none;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

  .off-line {
    color: var(--theme-error);
    cursor: pointer;
  }

  .on-line {
    color: var(--theme-on-surface);
    cursor: pointer;
  }

  .revision-title {
    color: var(--theme-on-surface);
  }

  .draft {
    color: var(--theme-secondary);
  }

  .buttons-wrapper {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr max-content;
    align-items: center;
    min-height: 35px;
    margin-left: 5px;

    .action-buttons {
      min-height: 35px;

      .variables {
        padding-left: 0;
      }
    }

    .paths {
      justify-content: center;
    }

    .activation-buttons {
      justify-content: flex-end;
    }

    > div {
      display: flex;
    }
    button {
      margin-right: 10px;
    }
  }

  .multiselect {
    width: 300px;
    grid-gap: 0px;
    display: inline;
  }

  .label {
    display: flex;
    font-weight: 500;
    color: var(--theme-primary);
  }
  .label > * {
    margin-right: 10px;
  }

  .label div:last-child {
    margin-left: auto;
  }

  .filter {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    font-size: 0.9rem;

    .checkbox-wrapper {
      margin: 0 5px;
      display: flex;
      align-items: center;

      .check-box {
        font-size: 0.75rem;
      }
    }
  }
}

.dock {
  margin-top: 50px;
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
<style lang="scss">
.wrp {
  height: calc(95% - 150px);

  .connection {
    .main-path {
      stroke-width: 3px;
    }
    &.input-nowhere {
      display: none;
    }
  }
  &.hide-eventSources {
    .connection {
      &.event-source {
        display: none;
      }

      &.event-source + div {
        display: none;
      }
    }
  }
  &.hide-workflow {
    .connection {
      &.event-source-workflow {
        display: none;
      }

      &.event-source-workflow + div {
        display: none;
      }
    }
  }
}
.tile {
  &.nowhere {
    display: none;
  }
}

.dock-item {
  display: inline-block;
  vertical-align: top;
  transform: scale(0.8);
  transform-origin: 50% 0;
  .tile {
    .statuses-wrapper {
      display: none;
    }
    &.start,
    &.finish {
      display: none;
    }
  }
}
.connection {
  &.event-source-workflow {
    path {
      stroke: yellow;
    }
  }
}
</style>
