var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"multiselect-wrapper"},[_c('multiselect',{attrs:{"id":_vm.id,"value":_vm.value,"options":_vm.options,"multiple":false,"searchable":false,"taggable":false,"show-no-results":false,"hide-selected":false,"show-labels":false,"show-no-options":false,"custom-label":_vm.getLabelCallback,"disabled":false},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [(props.option.type)?_c('span',{class:{
          offline: props.option.type === 'template' && !props.option.active,
          online: props.option.type === 'template' && props.option.active,
          draft: props.option.type === 'draft' || props.option.isDraft
        }},[_vm._v(" "+_vm._s(_vm.getStatusLabel(props.option)))]):_vm._e(),_c('span',[_vm._v(_vm._s(new Date(props.option.updatedAt || props.option.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s((" at " + (new Date(props.option.updatedAt || props.option.createdAt).toLocaleTimeString('en-US')))))])]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"parent"},[_c('div',{staticClass:"div1"},[_c('span',{class:{
              offline: props.option.type === 'template' && !props.option.active,
              online: props.option.type === 'template' && props.option.active,
              draft: props.option.type === 'draft' || props.option.isDraft
            }},[_vm._v(_vm._s(_vm.getStatusLabel(props.option)))])]),_c('div',{staticClass:"div2"},[_c('span',[_vm._v(_vm._s(new Date(props.option.updatedAt || props.option.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s((" at " + (new Date(props.option.updatedAt || props.option.createdAt).toLocaleTimeString('en-US')))))])]),_c('div',{staticClass:"div3"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(("by " + (props.option.author.split('@')[0]))))])]),_c('div',{staticClass:"div4"},[(props.option.commitMessage)?_c('span',[_vm._v("("+_vm._s(props.option.commitMessage)+")")]):_vm._e()])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }