<template>
  <div class="event">
    <div class="title" @click="$emit('openedStateChange')">
      <span> {{ isOpened ? '-' : '+' }} {{ event.code }} </span>
      <p-button
        v-if="event.type !== 'create' && $hasPermission('workflows')"
        variant="text"
        style="font-size: 1.5rem"
        @click.stop="removeEvent(event)"
        >&times;</p-button
      >
    </div>
    <div v-if="isOpened" class="body">
      <div class="control">
        <predicates
          :disabled="!$hasPermission('workflows')"
          :value="event.predicates"
          :source="source.source"
          :code="event.code"
          @input="onPredicatesChanged"
        />
      </div>
      <div v-if="event.type !== 'create'" class="control">
        <p-checkbox
          :disabled="!$hasPermission('workflows')"
          :value="event.action.delete"
          label="Mark milestone as deleted"
          @input="$e => onValueChanged('delete', $e)"
        />
      </div>
      <div v-if="event.type !== 'create'" class="control">
        <p-checkbox
          :disabled="!$hasPermission('workflows')"
          :value="event.action.finish"
          label="Mark milestone as finished"
          @input="$e => onValueChanged('finish', $e)"
        />
      </div>
      <div class="control">
        <h2 class="row-title">Milestone title:</h2>
        <p-wizard-title
          :disabled="!$hasPermission('workflows')"
          :value="event.action.title"
          :source="source.source"
          :code="event.code"
          :placeholder="'Milestone title'"
          @input="$e => onValueChanged('title', $e)"
        />
      </div>
      <div class="control">
        <p-wizard-due-at
          :disabled="!$hasPermission('workflows')"
          :value="event.action.dueAt"
          :source="source.source"
          :code="event.code"
          label="Due At:"
          @input="$e => onValueChanged('dueAt', $e)"
        />
        <p-wizard-due-at
          :disabled="!$hasPermission('workflows')"
          :value="event.action.clientDueDate"
          :source="source.source"
          :code="event.code"
          label="Client Due Date:"
          @input="$e => onValueChanged('clientDueDate', $e)"
        />
      </div>
      <div class="control">
        <h2 class="row-title">Assignees:</h2>
        <p-wizard-assignees
          :value="event.action.assignees"
          :disabled="!$hasPermission('workflows')"
          :source="source.source"
          :code="event.code"
          @input="$e => onValueChanged('assignees', $e)"
        />
      </div>
      <div class="control">
        <h2 class="row-title">Notes:</h2>
        <div v-for="(note, index) in event.action.notes || []" :key="index" class="note-wrapper">
          <p-wizard-title
            :disabled="!$hasPermission('workflows')"
            :value="note"
            :source="source.source"
            :code="event.code"
            :multiline="true"
            @input="$e => onChangeNotes($e, index)"
          />
          <p-button v-if="$hasPermission('workflows')" class="remove-notes-button" @click="onRemoveNote(index)"><md-icon name="close"/></p-button>
        </div>
        <p-button v-if="$hasPermission('workflows')" color="primary" @click="addNote()">Add note</p-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import InputWithVariables from '../workflow-editor/parts/InputWithVariables.vue';
import AssigneesWithVariables from '../workflow-editor/parts/AssigneesWithVariables.vue';
import DueDateWithVariables from '../workflow-editor/parts/DueDateWithVariables.vue';
import Button from '@/components/common/Button';
import Predicates from './wizard-parts/Predicates.vue';
import CheckBox from '@/components/common/Checkbox';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    'p-wizard-title': InputWithVariables,
    'p-wizard-assignees': AssigneesWithVariables,
    'p-wizard-due-at': DueDateWithVariables,
    'p-button': Button,
    'p-checkbox': CheckBox,
    Predicates,
    MdIcon
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: () => ({ action: {} })
    }
  },
  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts
    }),
    source: function() {
      return this.contracts.eventSources.find(s => s.events.some(es => es.code === this.event.code));
    }
  },
  methods: {
    onValueChanged(field, value) {
      const payload = { ...this.event.action, [field]: value };
      if (field === 'delete') {
        payload.finish = false;
      }
      if (field === 'finish') {
        payload.delete = false;
      }

      this.$emit('input', payload);
    },
    onPredicatesChanged(e) {
      this.$emit('predicateChanged', e);
    },
    removeEvent() {
      this.$emit('remove');
    },
    addNote() {
      const oldNotes = this.event.action.notes || [];
      this.onValueChanged('notes', [...oldNotes, '']);
    },
    onRemoveNote(index) {
      const oldNotes = [...this.event.action.notes] || [];
      oldNotes.splice(index, 1);

      this.onValueChanged('notes', oldNotes);
    },
    onChangeNotes($e, index) {
      const notes = [...this.event.action.notes];
      notes[index] = $e;
      this.onValueChanged('notes', notes);
    }
  }
};
</script>
<style lang="scss" scoped>
.event {
  border: 1px solid white;
  margin-bottom: 20px;
  .title {
    padding: 10px;
    border-bottom: 1px solid white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .body {
    padding: 10px;
  }

  &.default {
    .title {
      cursor: default;
    }
  }
  .row-title {
    margin-bottom: 10px;
  }
}
.control {
  margin-bottom: 20px;

  .note-wrapper {
    position: relative;
    .remove-notes-button {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}
</style>
